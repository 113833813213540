@media (min-width: 1280px) {
  .md\:logo:after {
    content: "";
    @apply absolute;
    @apply right-0;
    top: 12.5%;
    @apply block;
    height: 75%;
    @apply w-px;
    @apply bg-smoke-light;
    @apply my-0;
    @apply mx-3;
  }
}

@media (min-width: 1024px) {
  .md\:logo:after {
    content: "";
    @apply absolute;
    @apply right-0;
    top: 12.5%;
    @apply block;
    height: 75%;
    @apply w-px;
    @apply bg-smoke-light;
    @apply my-0;
    @apply mx-3;
  }
}

@media (min-width: 768px) {
  .md\:logo:after {
    content: "";
    @apply absolute;
    @apply right-0;
    top: 12.5%;
    @apply block;
    height: 75%;
    @apply w-px;
    @apply bg-smoke-light;
    @apply my-0;
    @apply mx-3;
  }
}

@media (min-width: 640px) {
  .sm\:logo:after {
    content: "";
    @apply absolute;
    @apply right-0;
    top: 12.5%;
    @apply block;
    height: 75%;
    @apply w-px;
    @apply bg-smoke-light;
    @apply my-0;
    @apply mx-3;
  }
}

.logo:after {
  content: "";
  @apply absolute;
  @apply right-0;
  top: 12.5%;
  @apply block;
  height: 75%;
  @apply w-px;
  @apply bg-smoke-light;
  @apply my-0;
  @apply mx-3;
}
