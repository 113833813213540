.dropdown-content {
  @apply absolute;
  @apply py-2;
  @apply w-48;
  @apply bg-white;
  @apply shadow-xl;
  @apply border;
  @apply hidden;
  @apply z-40;
  @apply opacity-0;
  top: calc(100% - 4px);
}

.dropdown-btn:after {
  content: "\2023";
  @apply ml-1;
  @apply font-semibold;
}

.dropdown-content.open,
.dropdown-btn.open:before {
  @apply opacity-100;
  @apply block;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

@media (min-width: 768px) {
  .dropdown-btn:before {
    top: calc(100% - 14px);
  }
}

.dropdown-btn:before {
  content: "";
  @apply bg-white;
  border-top-left-radius: 4px;
  width: 16px;
  height: 16px;
  @apply border-t;
  @apply border-l;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  @apply hidden;
  @apply absolute;
  @apply z-50;
  left: 8px;
  top: calc(100% - 8px);
  @apply opacity-0;
}
