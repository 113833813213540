.flex-basis-full {
  flex-basis: 100%;
}

.flex-basis-1\/2 {
  flex-basis: calc(100% / 2);
}

.flex-basis-1\/3 {
  flex-basis: calc(100% / 3);
}

.flex-basis-1\/4 {
  flex-basis: calc(100% / 4);
}

.flex-basis-1\/5 {
  flex-basis: calc(100% / 5);
}

.flex-basis-1\/6 {
  flex-basis: calc(100% / 6);
}

.flex-basis-1\/7 {
  flex-basis: calc(100% / 7);
}

.flex-basis-1\/8 {
  flex-basis: calc(100% / 8);
}

.flex-basis-1\/9 {
  flex-basis: calc(100% / 9);
}

/* medium */

@media (min-width: 640px) {
  .sm\:flex-basis-full {
    flex-basis: 100%;
  }

  .sm\:flex-basis-1\/2 {
    flex-basis: calc(100% / 2);
  }

  .sm\:flex-basis-1\/3 {
    flex-basis: calc(100% / 3);
  }

  .sm\:flex-basis-1\/4 {
    flex-basis: calc(100% / 4);
  }

  .sm\:flex-basis-1\/5 {
    flex-basis: calc(100% / 5);
  }

  .sm\:flex-basis-1\/6 {
    flex-basis: calc(100% / 6);
  }

  .sm\:flex-basis-1\/7 {
    flex-basis: calc(100% / 7);
  }

  .sm\:flex-basis-1\/8 {
    flex-basis: calc(100% / 8);
  }

  .sm\:flex-basis-1\/9 {
    flex-basis: calc(100% / 9);
  }
}

@media (min-width: 768px) {
  .md\:flex-basis-full {
    flex-basis: 100%;
  }

  .md\:flex-basis-1\/2 {
    flex-basis: calc(100% / 2);
  }

  .md\:flex-basis-1\/3 {
    flex-basis: calc(100% / 3);
  }

  .md\:flex-basis-1\/4 {
    flex-basis: calc(100% / 4);
  }

  .md\:flex-basis-1\/5 {
    flex-basis: calc(100% / 5);
  }

  .md\:flex-basis-1\/6 {
    flex-basis: calc(100% / 6);
  }

  .md\:flex-basis-1\/7 {
    flex-basis: calc(100% / 7);
  }

  .md\:flex-basis-1\/8 {
    flex-basis: calc(100% / 8);
  }

  .md\:flex-basis-1\/9 {
    flex-basis: calc(100% / 9);
  }
}

@media (min-width: 1024px) {
  .lg\:flex-basis-full {
    flex-basis: 100%;
  }

  .lg\:flex-basis-1\/2 {
    flex-basis: calc(100% / 2);
  }

  .lg\:flex-basis-1\/3 {
    flex-basis: calc(100% / 3);
  }

  .lg\:flex-basis-1\/4 {
    flex-basis: calc(100% / 4);
  }

  .lg\:flex-basis-1\/5 {
    flex-basis: calc(100% / 5);
  }

  .lg\:flex-basis-1\/6 {
    flex-basis: calc(100% / 6);
  }

  .lg\:flex-basis-1\/7 {
    flex-basis: calc(100% / 7);
  }

  .lg\:flex-basis-1\/8 {
    flex-basis: calc(100% / 8);
  }

  .lg\:flex-basis-1\/9 {
    flex-basis: calc(100% / 9);
  }
}

@media (min-width: 1280px) {
  .xl\:flex-basis-full {
    flex-basis: 100%;
  }

  .xl\:flex-basis-1\/2 {
    flex-basis: calc(100% / 2);
  }

  .xl\:flex-basis-1\/3 {
    flex-basis: calc(100% / 3);
  }

  .xl\:flex-basis-1\/4 {
    flex-basis: calc(100% / 4);
  }

  .xl\:flex-basis-1\/5 {
    flex-basis: calc(100% / 5);
  }

  .xl\:flex-basis-1\/6 {
    flex-basis: calc(100% / 6);
  }

  .xl\:flex-basis-1\/7 {
    flex-basis: calc(100% / 7);
  }

  .xl\:flex-basis-1\/8 {
    flex-basis: calc(100% / 8);
  }

  .xl\:flex-basis-1\/9 {
    flex-basis: calc(100% / 9);
  }
}
