h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  font-family: "Playfair Display", serif;
}

body {
  font-family: "Overpass", sans-serif;
}
