.flex-basis-full {
  flex-basis: 100%;
}

html {
  height: 100%;
}

body {
  height: 100%;
  position: relative;
}
