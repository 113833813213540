.stroke-black {
  stroke: #000;
}

.stroke-white {
  stroke: #fff;
}

.fill-black {
  fill: #000;
}

.fill-white {
  fill: #fff;
}

.stroke-width-3 {
  stroke-width: 3px;
}

.stroke-width-4 {
  stroke-width: 4px;
}

.fill-none {
  fill: none;
}

.miterlimit {
  stroke-miterlimit: 10;
}

.clip-evenodd {
  clip-rule: evenodd;
}

.isolate {
  isolation: isolate;
}