@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import "tools/flex-basis";

@import "backgrounds";

@import "fonts";

@import "layout";

@import "misc-utils";

@import "sizes";

@import "svgs";

@import "logo";

@import "dropdown";

@import "separators/horizontal";

@import "separators/vertical";

@import "toast";

.nukia-pink {
  color: #f7a3a3;
}

.nukia-blue {
  color: #3d70e0;
}
